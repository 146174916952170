import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import main from "./../main";
import store from "@/store/";

const loader: any[] = [];

export default class Api {
  private domain: string = process.env.VUE_APP_URL || "http://bms.local";

  private api: AxiosInstance;

  public constructor() {
    this.api = axios.create({
      baseURL: `${this.domain}/api/v1/`,
      timeout: 30000,
      withCredentials: true
    });

    // this middleware is been called right before the http request is made.
    this.api.interceptors.request.use((param: AxiosRequestConfig) => {
      loader.push(
        (main as any).$loading.show({
          "is-full-page": true
        })
      );

      return param;
    });

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.api.interceptors.response.use(
      (param: AxiosResponse) => {
        loader.forEach((x) => {
          x.hide();
        });

        return param;
      },
      (error) => {
        loader.forEach((x) => {
          x.hide();
        });
        if (error.response?.status == 401) {
          localStorage.removeItem("token");
          main.$router.replace({ name: "login" });
        }

        if (error.response?.status == 423) {
          main.$router.replace({ name: "dashboard" });
        }
        if (error.response?.status > 499) {
          store.commit("showError", {
            message: error.message,
            color: "danger"
          });
        }
        return Promise.reject({ ...error });
      }
    );
  }

  private createAxiosResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Reject promise if usual error
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

        return axios
          .post("/api/refresh_token", {
            refresh_token: localStorage.getItem("refresh_token")
          })
          .then((response) => {
            localStorage.setItem("refresh_token", response.data.refresh_token);
            error.response.config.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            return axios(error.response.config);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            main.$router.replace({ name: "login" });
            return Promise.reject(error);
          })
          .finally(this.createAxiosResponseInterceptor);
      }
    );

    return interceptor;
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config);
  }

  public request<T, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.api.request(config);
  }

  public get<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.delete(url, config);
  }

  public head<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.head(url, config);
  }

  public post<T, R = AxiosResponse<T>>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, R = AxiosResponse<T>>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, R = AxiosResponse<T>>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }
}

export class Api2 {
  private domain: string = process.env.VUE_APP_URL || "http://bms.local";

  private api: AxiosInstance;

  public constructor() {
    this.api = axios.create({
      baseURL: `${this.domain}/api/v1/`,
      timeout: 30000,
      withCredentials: true
    });
  }

  private createAxiosResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Reject promise if usual error
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

        return axios
          .post("/api/refresh_token", {
            refresh_token: localStorage.getItem("refresh_token")
          })
          .then((response) => {
            localStorage.setItem("refresh_token", response.data.refresh_token);
            error.response.config.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            return axios(error.response.config);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            main.$router.replace({ name: "login" });
            return Promise.reject(error);
          })
          .finally(this.createAxiosResponseInterceptor);
      }
    );

    return interceptor;
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config);
  }

  public request<T, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.api.request(config);
  }

  public get<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.delete(url, config);
  }

  public head<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.head(url, config);
  }

  public post<T, R = AxiosResponse<T>>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, R = AxiosResponse<T>>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, R = AxiosResponse<T>>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }
}
